import React, { Fragment } from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import OpenerVideo from '../components/OpenerVideo'
import OpenerImage from '../components/OpenerImage'
import OpenerMobile from '../components/OpenerMobile'
import ProjectSection from '../components/ProjectSection'
import ProjectCategories from '../components/ProjectCategories'
import Footer from '../components/Footer'

export class ProjectCategoryPageTemplate extends React.Component {
  render() {
    const {
      title,
      parentcategory,
      sectionOpener,
      subProjectCategories = [],
      motionGraphics = [],
      businessStories = [],
      liveStreaming = [],
      droneAerials = [],
      events = [],
      promos = [],
      tourism = [],
      slug
    } = this.props
    let categorySelector = []

    if (parentcategory == null) {
      categorySelector = subProjectCategories
    }
    if (!categorySelector || categorySelector.length <= 0) {
      if ('/project-categories/motion-graphics/' === slug) {
        categorySelector = motionGraphics
      } else if ('/project-categories/brand-stories/' === slug) {
        categorySelector = businessStories
      } else if ('/project-categories/live-streaming/' === slug) {
        categorySelector = liveStreaming
      } else if ('/project-categories/drone/' === slug) {
        categorySelector = droneAerials
      } else if ('/project-categories/events/' === slug) {
        categorySelector = events
      } else if ('/project-categories/promos-tvcs/' === slug) {
        categorySelector = promos
      } else if ('/project-categories/tourism/' === slug) {
        categorySelector = tourism
      } else {
        categorySelector = ''
      }
    }

    const categoryFrom = ('/project-categories/video-production/' === slug) ? 'landing' : '';

    let backPageLink = "/back-to-work/";
    let backPageLinkText = "Back";
    if (parentcategory == 'video production.') {
      backPageLink = "/back-to-video-production/";
      backPageLinkText = "Back To Video Production";
    }

    // console.log("***** slug", slug)
    // console.log("***** parentcategory", parentcategory) 
    // console.log("***** subProjectCategories", subProjectCategories)
    // console.log("***** categorySelector", categorySelector)
    // console.log("***** backPageLink", backPageLink)

    return (
      <Fragment>
        <div className="project">
          {!window.location.href.includes('#no-heading') && (
            <div className="section">
              <a className="arrow-down" href="#stories">
                {''}
              </a>
              {!!title && (
                <div className="full open">
                  <div className="taCenter thinText">
                    <h1>{title}</h1>
                    {!!sectionOpener.byline && <h3 className="smallText">{sectionOpener.byline}</h3>}
                  </div>
                </div>
              )}
              <div className="gradient" />
              {!!sectionOpener.youtubeVideoId && (
                <OpenerVideo
                  youtubeVideoId={sectionOpener.youtubeVideoId}
                  poster={sectionOpener.preloadImage}
                  alt={title}
                />
              )}
              {!sectionOpener.youtubeVideoId && !!sectionOpener.video && (
                <OpenerVideo
                  src={sectionOpener.video}
                  poster={sectionOpener.preloadImage}
                  alt={title}
                />
              )}
              {!!sectionOpener.image && !sectionOpener.youtubeVideoId && !sectionOpener.video && (
                <OpenerImage src={sectionOpener.image} alt={title} />
              )}
              {!!sectionOpener.mobileImage && (
                <OpenerMobile src={sectionOpener.mobileImage} alt={title} />
              )}
            </div>
          )}

          {!!categorySelector && (
            <div id="stories" className="">
              <div className="wide">
                <Link className="back" to={backPageLink}>{backPageLinkText}</Link>
                {subProjectCategories && subProjectCategories.length > 0 &&
                  <ProjectCategories categories={categorySelector} from={categoryFrom} />
                }
                {subProjectCategories && subProjectCategories.length <= 0 && 
                  <ProjectSection projects={categorySelector} />
                }
              </div>
            </div>
          )}
        </div>
        <Footer />
      </Fragment>
    )
  }
}

const ProjectCategoryPage = ({
  data: { page, subProjectCategories, motionGraphics, businessStories, liveStreaming, droneAerials, events, promos, tourism }
}) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ProjectCategoryPageTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      subProjectCategories={subProjectCategories && subProjectCategories.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      motionGraphics={motionGraphics && motionGraphics.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      businessStories={businessStories && businessStories.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      liveStreaming={liveStreaming && liveStreaming.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      droneAerials={droneAerials && droneAerials.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      events={events && events.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      promos={promos && promos.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      tourism={tourism && tourism.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)

export default ProjectCategoryPage

export const pageQuery = graphql`
  query ProjectCategoryPage($id: String!, $title: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      fields {
        slug
        contentType
      }
      frontmatter {
        title
        parentcategory
        sectionOpener {
          byline
          youtubeVideoId
          video
          image
          mobileImage
          preloadImage
        }
      }
    }

    subProjectCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "projectCategories" } }, frontmatter: { parentcategory: { eq: $title } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            slug
            sectionOpener {
              image
              mobileImage
              preloadImage
            }
          }
        }
      }
    }

    motionGraphics: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "motionGraphics" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            preview
            excerpt
            video
            youtubeVideoId
            featuredImage
          }
        }
      }
    }

    businessStories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "businessStories" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            preview
            featuredImage
            excerpt
            video
            youtubeVideoId
          }
        }
      }
    }

    liveStreaming: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "liveStreaming" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            preview
            featuredImage
            excerpt
            video
            youtubeVideoId
          }
        }
      }
    }

    droneAerials: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "droneAerials" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            preview
            featuredImage
            excerpt
            video
            youtubeVideoId
          }
        }
      }
    }

    events: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "events" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            preview
            featuredImage
            excerpt
            video
            youtubeVideoId
          }
        }
      }
    }

    promos: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "promos" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            preview
            featuredImage
            excerpt
            video
            youtubeVideoId
          }
        }
      }
    }

    tourism: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "tourism" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            preview
            featuredImage
            excerpt
            video
            youtubeVideoId
          }
        }
      }
    }

  }
`
